
import instImg1 from "../images/instragram/1.jpg"
import instImg2 from "../images/instragram/2.jpg"
import instImg3 from "../images/instragram/3.jpg"
import instImg4 from "../images/instragram/4.jpg"
import instImg5 from "../images/instragram/5.jpg"
import instImg6 from "../images/instragram/1.jpg"



const Instagram = [
    {
        id:"1",
        imag:instImg1,
        title: 'Charity',
        subtitle: 'Pure Water Supply',
    },
    {
        id:"2",
        imag:instImg2,
        title: 'Charity',
        subtitle: 'Pure Water Supply',
    },
    {
        id:"3",
        imag:instImg3,
        title: 'Charity',
        subtitle: 'Pure Water Supply',
    },
    {
        id:"4",
        imag:instImg4,
        title: 'Charity',
        subtitle: 'Pure Water Supply',
    },
    {
        id:"5",
        imag:instImg5,
        title: 'Charity',
        subtitle: 'Pure Water Supply',
    },
    {
        id:"6",
        imag:instImg6,
        title: 'Charity',
        subtitle: 'Pure Water Supply',
    },
]

export default Instagram;